.container {
  width: 100%;
  padding: 0px;
}
.header {
  margin-top: -1px;
  height: 50px !important;
  width:100% !important;
  position: fixed;
  z-index: 10;
  background-image: radial-gradient(ellipse farthest-corner at 0 3000%, #56b146 72%, #008cd2 72%);
}


.smallSize{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.header .customNav {
  border-radius: 0 !important;
  height: 20px;
  background-size: 28%;
  width: 100%;
  vertical-align: middle;
  margin-right: 0px;
  margin-left: 0px;
}
.ham-icon {
  float: left;
  height: 50px;
  padding-top: 11px;
  color: white;
  font-size: 24px;
  padding-left: 8px;
  padding-right: 8px;
}

.header .bar1,
.header .bar2,
.header .bar3 {
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin-bottom: 6px;
  transition: 0.4s;
}

.hide-wrapper {
  width: 0px !important;
}
.header .sidebar-nav {
  list-style-type: none;
  padding-left: 0px;
}
.header .sidebar-nav li {
  line-height: 3.1;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  color: rgb(255, 255, 255) !important;
  border-bottom: 1px solid grey;
}
.header .sidebar-nav li .btn {
  width: 100%;
  text-align: left;
  border: none;
  background-color: inherit;
  color: white;
  outline: none;
}
.header .sidebar-nav li .btn:hover {
  border-left: 2px solid rgb(78, 144, 175);
  color: white !important;
  border-radius: 0px;
}
.header .sidebar-nav li span {
  padding-left: 10px;
  font-size: 15px;
  padding-top: 3px;
  position: absolute;
}
.header .sidebar-nav li .glyphicon-menu-right {
  font-size: 15px;
  font-weight: 100;
  padding-left: 15px;
  top: 13px;
  position: absolute;
  float: right;
  right: 10px;
}

.nav-tabs {
  border-bottom: none;
}
.nav-tabs>li>a {
  border:none;
}
.gap {
  width: 45px;
  height: 17px;
  float: left !important;
  display: inline-block;
}

.header-name{
  color: white;
  font-weight: 700;
  position: relative;
  top: -2px;
}

.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:hover,
.navbar-default .navbar-nav > li.active > a:focus {
  /* background-color: #fff;
  font-weight: bold;
  font-size: 10px;
  color: #6d6969;
*/
  padding: 1px 5px 1px 5px; 
  background-color: #008cd2;
  font-weight: bold;
  font-size: 11px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
  /* padding: 2px; */
  /* border-radius: 11px 11px 11px 11px; */
  margin-top: 13px;
  font-size: 11px;
  font-weight: bold;
  /* font-size: 10px; */
  padding: 1px 5px 1px 5px;
}
.navbar-default .navbar-nav > li > a:hover {
  /* background-color: #fff;
  font-weight: bold;
  font-size: 10px;
  color: #6d6969;*/
  padding: 1px 5px 1px 5px; 
  background-color: #008cd2;
  font-weight: bold;
  font-size: 11px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid;
}

.header-items {
  display: flex;
  float: right;
}

.logo {
  color: white;
  font-size: 22px;
  font-weight: bold;
  float: left;

  margin-top: 19px;
  margin-left: 4px;
  font-style: italic;
}

.ant-btn-primary {
  margin-top: 9px;
  margin-bottom: 8px;
  color: #fff;
  padding: 5px !important;
  background-color: #56b146 !important;
  border: none !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  --antd-wave-shadow-color: #56b146;
}
.anticon {
  font-size: 24px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #56b146 !important;
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
  height: 100vh;
  background: #214150;
}

.nav {
  /* padding-left: 0; */
  margin-bottom: 0;
  list-style: none;
}
.nav-stacked>li+li {
  margin-top: 0px;
  margin-left: 0;
}

.user:hover{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
.off:hover{
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5); 
}
.header .mySidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 52px;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  /* transition: 0.5s; */
}

.header .sidebar-wrapper {
  position: absolute;
  height: calc(100% - 50px);
  width: 250px;
  box-shadow: 2px 0px rgb(201, 200, 200, 0.4);
  z-index: 5000;
  -webkit-transition: all 10s;
  transition: 0.3s !important;
  margin-top: 50px;
  background-color: rgb(88, 87, 87);
}


.header-name{
  color: white;
  font-weight: 700;
}

.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
  color: #fff;
  background-color: #0f405873;
}

.header-items {
  justify-content: space-between;
  display: flex;
}


.headStyle{
  height: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.navbar-inverse {
  background-color: #2220;
  border: none;
}

@media (min-width: 1025px){
  .navbar-nav>li {
      float: left !important;
}
.nav-pills>li>a {
  border-radius: 0px;
}
.nav>li>a {
  position: relative;
  display: block;
  margin-right: 1px;
  padding: 14px 6px !important;
  margin-top: 0px !important;
}
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-bottom:  solid;
}

.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
  border-bottom: solid;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* background-color: #0f405800; */
}
.navbar-inverse .navbar-nav>li>a:hover {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  border-bottom:  solid;
}
.navbar-nav>li>.dropdown-menu {
  background-color: #008cd2e6;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #fff7f7;
  white-space: nowrap;
}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #24628359;
}
.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #235a76db;
  outline: 0;
}
}

@media (max-width: 1024px){
  .navbar-nav>li {
    float: none !important;
} .navbar-nav{
  background-color:#008cd2c4;
  width: 150px;
}
}


.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: #0f405873;
}
.navbar-inverse .navbar-nav>li>a {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
}
.nav>li>a {
  position: relative;
  display: block;
  margin-right: 1px;
  padding: 7px 6px;
  margin-top: 9px;
}

@media (max-width: 768px){
.navbar-nav {
    float: left;
    background-color: #008cd2;
    margin-top: 0px !important;
}}

@media (min-width: 768px){
  .container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
      margin-right: -10px !important;
      margin-left: 0;
}
}


