.checkdetailsContent {
  background-color: #b3c2cc;
  padding: 9px;
  overflow-x: auto;
}

.checkdetailsbox {
  /* background-color: rgba(214, 239, 251); */
  background-color: white;
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 9px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.checkdetailsheading {
  text-align: left;
  font-size: 12px;
  margin: 2px;
  height: 35px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  padding-top: 0.5%;
  border-bottom: 1px solid gray;
}

.a {
  margin-bottom: 10px;
  width: 70%;
display: -webkit-inline-box;
}

.tabb{
width: 70%;
display: -webkit-inline-box;
}

.viewboxd {
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  margin-top: 10px;
}