.tablecontent {
    background-color: #b3c2cc !important;
    height: 89Vh;
  }
.tablecontent .nav-tabs > li > a {
    margin-top: 11px !important;
  }
.tabViewContent {
    background-color: #b3c2cc !important;
    height: calc(100vh - 89px);
    overflow-y: scroll;
    padding: 9px;
  }
.tableBox{
    background-color: white !important;
    /* padding-bottom: 1.5%; */
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
    /* padding: 1% !important; */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }
.topBox{
    padding: 1%;
}
.secondBox{
    padding:1%;
    /* padding-left: 2.5%; */
    text-decoration: underline;
}
.upperBodys {
  background-color: white !important;
  padding: 7px !important;
  margin-top: 21px !important;
  border-radius: 16px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.resultTab {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  /* float:left; */
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
}
.resultTable {
  /* height: 33px; */
  background-color: white !important;
  /* padding: 5px !important; */
  margin-top: 21px !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5) !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.upperBodyTable2 {
  background-color: white !important;
  padding: 7px !important;
  /* margin-top: 21px !important; */
  border-radius: 16px !important;
  display: flex;
    flex-direction: column;
}
.right-to-left-buttons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.right-to-left-buttons > button {
  margin: 4px 8px;
}

/*  */
/* .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 11px;
}
.ReactTable .rt-tbody .rt-td  {
  text-align: center;
  height: unset !important;
} */
.link-view {
  text-decoration: underline;
  cursor: pointer;
}
.header {
  width: 100%;
  background-color: aqua;
  height: 75px;
  background-image: url("../../assets//images/header.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.glyphButton:hover {
  background-color: transparent;
}
/* .glyphButton:select {
  background-color: transparent;
} */
.headerRow {
  margin-bottom: 10px;
}
.tableContent {
  background-color: #b3c2cc;

  /* height: 84vh; */
  /* padding:9px; */
}
.tabViewContent {
  background-color: #b3c2cc !important;
  /* margin-top: 20px !important; */

  padding: 9px;
  /* padding-right: .5%;
  padding-left: .5% !important; */
}
/* .ReactTable {
  padding: 10px;
  border-radius: 10px;
  border: rgb(63, 65, 63) !important;
} */
/* .ReactTable .rt-tbody .rt-td:hover {
  border: 2px solid green;
} */
/* .ReactTable .rt-tbody .rt-tr-group:hover{
background-color: rgb(223, 250, 250);
}
.ReactTable .rt-tbody .rt-tr-group .-even:hover{
  background-color: rgb(5, 15, 15);
  } */
  .closeTable {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    /* margin-bottom: 0 !important; */
    font-size: 12px;
    font-weight: 600;
  }
/* .ReactTable .-pagination .-btn:hover {
  background-color: green !important;
} */
/* .form-control {
  width: 15% ;
  float: right;
} */
.-striped {
  background-color: white;
  font-family: sans-serif;
}
/* .ReactTable .-pagination .-previous  {
  width: 30% !important;
  height: 80% !important;
}
.ReactTable .-pagination .-next  {
  width: 30% !important;
  height: 80% !important;
} */
/* @media (min-width: 426px) {
  .ReactTable .-pagination {
    padding-left: 13% !important;
   
  } */
  /* .ReactTable .-pagination .-btn {
    width: 50% !important;
  }
  .button {
    margin-right: -55px !important;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 0px 0px 0px 0px !important;
  }
  .ReactTable .pagination-bottom {
    width: 100% !important;
  }
}
.ReactTable .rt-thead .rt-th {
  background-color: #b3c2cc !important;
}
.ReactTable .rt-thead {
  height: 50%;
}
.ReactTable .rt-tbody .rt-td {
  font-size: 11px;
  height: unset !important;
}
.ReactTable .rt-tbody .rt-tr {
  padding: 0 0 0 0px !important;
}
.ReactTable .rt-tbody .rt-tr {
  padding-right: 0px !important;
}
.ReactTable .rt-tbody .-even {
  background-color: #d8dde0 !important;
}
.ReactTable .rt-tbody .-odd {
  background-color: rgba(0,0,0,0.03) !important;
} */
.checkbox{
  width:50% !important; 
   margin:auto !important;
}

.tablebox1 {
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.tablebox2 {
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  margin-top: 9px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.fcwidth {
  width: 18%;
  float: right;
}

.claimtype {
  font-family: sans-serif;
}

.popupheading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 12px !important;
}

.h3.popupheading {
  margin-top: 0% !important;
}

.company {
  font-size: 14px;
  font-weight: 600;
}

.claimsubtype {
  font-size: 14px;
  font-weight: 600;
}

.subtypes {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1%;
}
.showfullString{
  text-align:left !important;
  white-space:nowrap !important;
  text-overflow:ellipsis !important;
  overflow:hidden !important;
  cursor: pointer;
}

.ant-tooltip{
  max-width: max-content;
  font-size: 12px;
}

