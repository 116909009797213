.responseContent {
  max-height: 84Vh;
  background-color: #b3c2cc;
  padding: 9px;
  overflow-x: auto;
  font-size: 12px;
  }
  
  .responsebox {
    /* background-color: rgba(214, 239, 251); */
    background-color: white;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 9px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }
  
  .responseheading {
    width: 100%;
    text-align: left;
    /* font-weight: bold; */
    font-size: 12px;
    margin: 2px;
    height: 35px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    padding-top: 0.5%;
    border-bottom: 1px solid gray;
  }

  .coloring {
      background-color: #b9d6e9;
      border-radius: 5px;
      padding-bottom: 1%;
      padding-top: 1%;
      font-weight: 700;
      /* color: rgb(201, 195, 195)' */
  }