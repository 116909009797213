/* .dynamic-tab-view,
.tableContent .nav-tabs {
  background: #b3c2cc;
  border-bottom: 1px solid #e6f1f8;
  display: flex;
}

.nav-tabs {
  padding-left: 9px;
}

.nav-tabs > li {
  float: left;
}

.dynamic-tab-view .nav-tabs > li.active > a,
.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li.active > a,
.tableContent .nav-tabs > li > a {
  padding: 5px 8px 5px 8px;
  margin-top: 11px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
}

.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li > a {
  color: #000000;
  text-decoration: none;
  background: linear-gradient(to bottom, #ffffff 0%, rgb(235, 236, 236) 100%);
}

.dynamic-tab-view .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: #008cd2;
  background: linear-gradient(to bottom, #1c77a5 0%, #008cd2 100%);
}

.tableContent .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(to bottom, rgb(52, 158, 33) 0%, rgb(86, 177, 70) 100%);
}

.side-tab {
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
  background: rgb(138, 149, 156);
}

.del-tab-deactive {
  display: none;
}
.del-tab-active {
  background: #008cd2;
  color: white;
}
.ref-tab {
  background: #56b146;
  color: white;
}
.tab-space {
  justify-content: space-between;
} */

.ant-btn-dangerous {
  color: #fff !important;
  background:#d9363e  !important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  height: 30px;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
  color: #ffffff;
  text-decoration: none;
  background: #008cd2;
  background: linear-gradient(to bottom, #1c77a5 0%, #008cd2 100%);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
  font-weight: 500;
}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0 0;
}

.ant-tabs-tab-active .ant-tabs-tab-remove .anticon-close {
  margin: 0;
  color: #ffffff;
}
.ant-tabs-tab-remove {
  height: 18px;
  -ms-flex: none;
  flex: none;
  margin-right: -4px;
  margin-left: 0px;
    color:#6d5e5e;
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  margin-top: 11px;
  color:#6d5e5e;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 12px;
    border: none;
    outline: none;
    font-weight: 600;
    margin-right: 4px;
    /* line-height: 30px; */
  position: relative;
  /* display: inline-block; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* padding-left: 0; */
  list-style: none;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.dynamic-tab-view,
.tableContent .nav-tabs {
  background: #b3c2cc;
  border-bottom: 1px solid #e6f1f8;
  display: flex;
}

.nav-tabs {
  padding-left: 9px;
}

.nav-tabs > li {
  float: left;
  /* margin-bottom: 0px; */
}

.dynamic-tab-view .nav-tabs > li.active > a,
.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li.active > a,
.tableContent .nav-tabs > li > a {
  /* border-top-right-radius: 5px; */
  /* border-top-left-radius: 5px; */
  padding: 5px 8px 5px 8px;
  margin-top: 11px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
}

.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li > a {
  color: #000000;
  text-decoration: none;
  background: linear-gradient(to bottom, #ffffff 0%, rgb(235, 236, 236) 100%);
}

.dynamic-tab-view .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: #008cd2;
  background: linear-gradient(to bottom, #1c77a5 0%, #008cd2 100%);
}

.tableContent .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(to bottom, rgb(52, 158, 33) 0%, rgb(86, 177, 70) 100%);
}

.side-tab {
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  /* border-bottom-right-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
  background: rgb(138, 149, 156);
}

.del-tab-deactive {
  display: none;
}
.del-tab-active {
  background: #008cd2;
  color: white;
}
.ref-tab {
  background: #56b146;
  color: white;
}
.tab-space {
  justify-content: space-between;
}

.alertboxheader{
  display: inline;
  border-radius: 4px;
  box-shadow: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #ed6c02;
  display: flex;
  padding: 6px 16px;
  color: #000000;
  margin-left: 25%;
  margin-right:25%;
  margin-top:6px;
  z-index: 10;
  text-align: center;
}

.close-button{
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: .7;
  transition: .3s ease;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}