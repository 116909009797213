.loginInput {
    width: 50%;
}
.anticon {
    font-size: 16px;
}


.loginBody {
    /* background: #457fca;   
    background: -webkit-linear-gradient(left, #5691c8, #457fca);
    background: -o-linear-gradient(left, #5691c8, #457fca);
    background: linear-gradient(to right, #5691c8, #457fca);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    background-image: url('../../assets/images/login_bg.jpg');
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: cover; */
    left: 0;
     height: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-top:-65px !important; */
}
.background-img{
    background: #457fca;   
    background: -webkit-linear-gradient(left, #5691c8, #457fca);
    background: -o-linear-gradient(left, #5691c8, #457fca);
    background: linear-gradient(to right, #5691c8, #457fca);
    background-attachment: fixed;
    background-image: url('../../assets/images/login_bg.jpg');
    background-repeat: repeat;
    background-size: cover;
    height:100Vh;
    overflow: hidden;
}
.loginBody .container {
    text-align: center;
    width: 100%;
    height: 100vh; 
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}



.loginCard {
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 2px rgba(0, 0, 0, 0.24);
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) );
    margin-top: 9%;
    /* margin-left: 38%; */
    padding: 25px;
    max-width: 385px;
    width:100%;
    border-radius: 0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* position: relative; */
    top: 26%;
   -webkit-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.inputsSection {
    padding: 5%;
    border-radius: 10px;
    
}
.errorMsg {
    color: #fffefe;
    padding: 5px;
    text-align: left;
    /* border: 1px solid #ce5353; */
    border-radius: 5px;
    /* margin-bottom: 15px; */
    font-size: 12px;
    font-weight: bolder;
    background: #ce5353;
}

.username,
.password {
    border-left: none;
    border-radius: 0 0.3rem 0.3rem 0;

   
}
.loginclass{
    display: none;
}
.greenMargin{
    border: 3px solid #eee;
    border-radius: 0.3em;

}
.loginMsg{
    text-align: left;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 2px rgba(0, 0, 0, 0.24);
    background-image: linear-gradient(rgba(245, 243, 243, 0.5), rgba(251, 247, 247, 0.79) );
    font-weight:500;
    padding: 14px;
    width: 96%;
    border-radius: 23px;
    /* border-radius: 0.5em; */
    display: block;
    margin-left: 2%;
    margin-right: 2%;
    /* position: absolute; */
    /* bottom: -50px; */
    margin-bottom: -4%;
    color:white;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.loginButton {
    background: rgb(81,186,56) !important;
    color: white;
    width: 100%;
    border: none;
    margin-top:15px;
    border-radius: 0.3rem;
    height: 32px;
    
}

.loginButton:focus {
    background:rgb(173,196,218);
    color: rgb(66,106,147);
}
.spanT{

    color: rgb(0,124,195);
    padding-left: 10px!important;
    font-size: 200%;
    z-index: 1;
    position: relative;
    text-align: left;
    border-left: 4px solid rgb(81,186,56);
    /* margin-left: 247px; */
    /* margin-top: 56px; */
    margin-left: 19%!important;
    margin-top: 6.5%!important;
 
}

#userId {
    width:100% !important
}

#passWord {
    width:100% !important
}
.head_img{
    padding-left: 2%;
    padding-top: 3%;
    width: 18%;
    float : left;
    display: inline-block;
    height:100%;
    z-index: 1; position:relative;
    background-size:contain;
}

.content{
    margin-top: 61px;
    display: inline-block;
    /* float: left; */
    font-size: 22px;
    color: blue;
}


.alertbox{
    display: inline;
    border-radius: 4px;
    box-shadow: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #ed6c02;
    display: flex;
    padding: 6px 16px;
    color: #fff;
    text-align: left;
    margin-left: 20%;
    margin-right:20%;
    margin-top: 1%;
    z-index: 10;
    position: fixed;
    right: 0;
    left: 0;
}
.subjectContainer{
    text-align: left;
    color: white;
    font-weight: 700;
    padding-left: 15px;
}
.messageContainer{
    text-align: left;
    padding-left: 10px;
    color: #000;
    font-size: .7em;
}