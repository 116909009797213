.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.space {
  margin-bottom: 5px;
  margin-left: -15px !important;
}
.space .form-control {
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  min-height: 32px;
  overflow-x: auto;
  white-space: pre;
}
.space .form-control p {
  margin-bottom: 0px;
}
.Heading {
  font-weight: bold;
  padding: 2%;
  text-align: center;
}
.Table {
  padding: 2%;
}

.suppWEBContent {
  background-color: #b3c2cc;
  height: 86vh;
  padding: 9px;
  overflow-x: auto;
}

.outerbox {
  background-color: white;
  /* padding-bottom: 1%; */
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 9px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.boxd {
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  margin-top: 10px;
}
#pdfobject {
  height: 76vh;
}
.mainBox {
  padding-top: 2%;
  padding-bottom: 2%;
}
.requestBtn {
  padding-top: 50px;
}
 .box{
   height: 76Vh;
 }