.outerboxpass {
    background-color: white;
    
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 9px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .passheading {
    text-align: left;
    font-size: 12px;
    margin: 2px;

    height: 35px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    padding: 1%;
    border-bottom: 1px solid gray;
  } 
  .password {
    background-color: #b3c2cc ; 
    
    padding:9px;
    overflow-x: auto;
  }
  .entryBox{
      width: 30%;
      display: inline;
  }
.margins{
    padding:3%;
}
.bottomspace{
    padding-bottom: 1%;
}
.highlight{
    border-color:red;
    width: 30%;
    display:inline;
}