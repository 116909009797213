body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: left;
  background: #b3c2cc;
  height:115Vh;}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  width: 100%;
  padding: 0px;
}
.header {
  margin-top: -1px;
  height: 50px !important;
  width:100% !important;
  position: fixed;
  z-index: 10;
  background-image: radial-gradient(ellipse farthest-corner at 0 3000%, #56b146 72%, #008cd2 72%);
}


.smallSize{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.header .customNav {
  border-radius: 0 !important;
  height: 20px;
  background-size: 28%;
  width: 100%;
  vertical-align: middle;
  margin-right: 0px;
  margin-left: 0px;
}
.ham-icon {
  float: left;
  height: 50px;
  padding-top: 11px;
  color: white;
  font-size: 24px;
  padding-left: 8px;
  padding-right: 8px;
}

.header .bar1,
.header .bar2,
.header .bar3 {
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin-bottom: 6px;
  transition: 0.4s;
}

.hide-wrapper {
  width: 0px !important;
}
.header .sidebar-nav {
  list-style-type: none;
  padding-left: 0px;
}
.header .sidebar-nav li {
  line-height: 3.1;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  color: rgb(255, 255, 255) !important;
  border-bottom: 1px solid grey;
}
.header .sidebar-nav li .btn {
  width: 100%;
  text-align: left;
  border: none;
  background-color: inherit;
  color: white;
  outline: none;
}
.header .sidebar-nav li .btn:hover {
  border-left: 2px solid rgb(78, 144, 175);
  color: white !important;
  border-radius: 0px;
}
.header .sidebar-nav li span {
  padding-left: 10px;
  font-size: 15px;
  padding-top: 3px;
  position: absolute;
}
.header .sidebar-nav li .glyphicon-menu-right {
  font-size: 15px;
  font-weight: 100;
  padding-left: 15px;
  top: 13px;
  position: absolute;
  float: right;
  right: 10px;
}

.nav-tabs {
  border-bottom: none;
}
.nav-tabs>li>a {
  border:none;
}
.gap {
  width: 45px;
  height: 17px;
  float: left !important;
  display: inline-block;
}

.header-name{
  color: white;
  font-weight: 700;
  position: relative;
  top: -2px;
}

.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active > a:hover,
.navbar-default .navbar-nav > li.active > a:focus {
  /* background-color: #fff;
  font-weight: bold;
  font-size: 10px;
  color: #6d6969;
*/
  padding: 1px 5px 1px 5px; 
  background-color: #008cd2;
  font-weight: bold;
  font-size: 11px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
  /* padding: 2px; */
  /* border-radius: 11px 11px 11px 11px; */
  margin-top: 13px;
  font-size: 11px;
  font-weight: bold;
  /* font-size: 10px; */
  padding: 1px 5px 1px 5px;
}
.navbar-default .navbar-nav > li > a:hover {
  /* background-color: #fff;
  font-weight: bold;
  font-size: 10px;
  color: #6d6969;*/
  padding: 1px 5px 1px 5px; 
  background-color: #008cd2;
  font-weight: bold;
  font-size: 11px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid;
}

.header-items {
  display: flex;
  float: right;
}

.logo {
  color: white;
  font-size: 22px;
  font-weight: bold;
  float: left;

  margin-top: 19px;
  margin-left: 4px;
  font-style: italic;
}

.ant-btn-primary {
  margin-top: 9px;
  margin-bottom: 8px;
  color: #fff;
  padding: 5px !important;
  background-color: #56b146 !important;
  border: none !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  --antd-wave-shadow-color: #56b146;
}
.anticon {
  font-size: 24px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #56b146 !important;
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
  height: 100vh;
  background: #214150;
}

.nav {
  /* padding-left: 0; */
  margin-bottom: 0;
  list-style: none;
}
.nav-stacked>li+li {
  margin-top: 0px;
  margin-left: 0;
}

.user:hover{ /* IE 9 */ /* Safari 3-8 */
  transform: scale(1.5); 
}
.off:hover{ /* IE 9 */ /* Safari 3-8 */
  transform: scale(1.5); 
}
.header .mySidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 52px;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  /* transition: 0.5s; */
}

.header .sidebar-wrapper {
  position: absolute;
  height: calc(100% - 50px);
  width: 250px;
  box-shadow: 2px 0px rgb(201, 200, 200, 0.4);
  z-index: 5000;
  transition: 0.3s !important;
  margin-top: 50px;
  background-color: rgb(88, 87, 87);
}


.header-name{
  color: white;
  font-weight: 700;
}

.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
  color: #fff;
  background-color: #0f405873;
}

.header-items {
  justify-content: space-between;
  display: flex;
}


.headStyle{
  height: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.navbar-inverse {
  background-color: #2220;
  border: none;
}

@media (min-width: 1025px){
  .navbar-nav>li {
      float: left !important;
}
.nav-pills>li>a {
  border-radius: 0px;
}
.nav>li>a {
  position: relative;
  display: block;
  margin-right: 1px;
  padding: 14px 6px !important;
  margin-top: 0px !important;
}
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-bottom:  solid;
}

.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
  border-bottom: solid;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.05) !important;
  /* background-color: #0f405800; */
}
.navbar-inverse .navbar-nav>li>a:hover {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  border-bottom:  solid;
}
.navbar-nav>li>.dropdown-menu {
  background-color: #008cd2e6;
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #fff7f7;
  white-space: nowrap;
}
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #24628359;
}
.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #235a76db;
  outline: 0;
}
}

@media (max-width: 1024px){
  .navbar-nav>li {
    float: none !important;
} .navbar-nav{
  background-color:#008cd2c4;
  width: 150px;
}
}


.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: #0f405873;
}
.navbar-inverse .navbar-nav>li>a {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
}
.nav>li>a {
  position: relative;
  display: block;
  margin-right: 1px;
  padding: 7px 6px;
  margin-top: 9px;
}

@media (max-width: 768px){
.navbar-nav {
    float: left;
    background-color: #008cd2;
    margin-top: 0px !important;
}}

@media (min-width: 768px){
  .container-fluid>.navbar-collapse, .container-fluid>.navbar-header, .container>.navbar-collapse, .container>.navbar-header {
      margin-right: -10px !important;
      margin-left: 0;
}
}





.ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 11px;
}
.ReactTable .rt-tbody .rt-td {
  text-align: center;
  height: 23px !important;
  font-size: 11px;
}
.link-view {
  text-decoration: underline;
  cursor: pointer;
  color: #1890ff;
}
.ReactTable .-pagination .-btn {
  background-color: #3ba229 !important;
  color: #f7eeee !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: #3ba229 !important;
  color: #f7eeee !important;
}
.glyphButton:hover {
  background-color: transparent;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    font-weight: bold !important;
}
.headerRow {
  display: flex;
  /* margin-bottom: 15px; */
}
.claimDropdown{
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 18px
}
.tabViewContent {
  background-color: #b3c2cc !important;

  padding: 9px;
}
.oneLine{
  padding-left:10px;
}
.ReactTable {
  /* margin-top: 2%; */
  padding: 10px;
  border-radius: 10px;
  border: rgb(63, 65, 63) !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: green !important;
}
.first-column {
  text-align: left !important;
}

.-striped {
  background-color: white;
  font-family: sans-serif;
}

@media (min-width: 426px) {
  .ReactTable .-pagination {
    padding-left: 13% !important;
  }
  .ReactTable .-pagination .-btn {
    width: 50% !important;
  }
  .button {
    margin-right: -55px !important;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 0px 0px 0px 0px !important;
  }
  .ReactTable .pagination-bottom {
    width: 100% !important;
  }
}
.ReactTable .rt-thead .rt-th {
  background-color: #b3c2cc !important;
}
.ReactTable .rt-thead {
  height: 50%;
}

.ReactTable .rt-tbody .rt-tr {
  padding: 0 0 0 0px !important;
}

.ReactTable .rt-tbody .-even {
  background-color: #d8dde0 !important;
}
.link-view {
  text-decoration: underline;
  cursor: pointer;
}
.header {
  width: 100%;
  background-color: aqua;
  height: 75px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABVYAAAAwCAIAAAECyYYzAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMjSURBVHhe7dfraVwxFIVRV5cSUpWbSSuuKAFDMJ/xMI/7kHQXrP+jOdra0n37/efXMd7ePxhR9ukwXQdnycacpcviMNmJEXSJ7CrTH02Xy+Yy8WF13Wwlg95Pf5hBZJ8O03VwlmzMKbomjpTNOF3Xx96yAUPpWtlDhj6srputZNDr6R8GvsqBuZqOA64mR4JPHROsKtHnto4PZpeI85yOFWaRKLOVDhrGlODOrn8PuCHnZzH9t8BXOTBr65+Hi8sJuZTOAq4mR+LKOhpYXs4AnzomWFJyz3cdGSwjWee2jg+mlnxzp84RZpRY84TOFGaRKPOKDhcGlwSzlQ4aBpTUMrjuHwA8JxcMU+vuAsBPcoWwsO49AFeWS4JraiwAWF5uAogmBoA1pO7hTk0SAHNJrcOLmjAAxpT6hp00eQCcKzUNR2ocAThMGhlO14wCsIeUL4ypwQXgRelZmEWjDMBD0qoMpbsFAFvJlcPgun8A8JxcMMyl2wkAd8qNwtS6uwDwk1whrKSbDQD/5c5gYd17AK4slwQX0RwAcDW5GLimxgKA5eUmgEYEgCWl/SGaGADWkLqHG5oeAOaVioc7NUkAzCW1Dk9oqgAYX6ocXtSEATCm1DdsqGkDYBypbNhDYwfA6dLUsLdGEIBTpJ3hSI0jAIdJI8MpmksA9pYihtM1owDsIeUL42hYAdhQOhcG1NQC8LpULQyuCQbgOalXmEWjDMBD0qrA03q6AAAAhpJvGGA/PX4AAABHyicKcJYeTgAAgG3lIwQYU48uAADAo/KZAcyoBxsAAOC7fEgA6+mxBwAArimfCsCltBEAAICF5XsA4FPLAgAAmF0e/QC3tUQAAIBZ5HEP8LT2CwAAMJS84AE2194BAABOkZc6wGHaRwAAwK7yIgc4XXsKAADYRF7eAMNqfwEAAA/JCxtgLi01AADgJ3lMA6yhZQcAAOTRDLCwNiAAAFxK3scAV9NaBACAVeUpDMA/7UoAAFhAXr0A/KQFCgAAc8kDF4CHtFUBAGBYecsC8KL2LAAADCIvVwD20PIFAIDj5ZEKwDFaxwAAsKv3j79yUH9XgVtL6AAAAABJRU5ErkJggg==);
  background-size: cover;
  background-repeat: no-repeat;
}

.glyphButton:hover {
  background-color: transparent;
}
/* .glyphButton:select {
  background-color: transparent;
} */
.headerRow {
  display: flex;
  margin-bottom: 15px;
  margin-right: 0px !important;
}
.tableContent {
  background-color: #b3c2cc;
}
.tabViewContent {
  background-color: #b3c2cc !important;

  padding: 9px;
}
.ReactTable {
  /* margin-top: 2%; */
  padding: 10px;
  border-radius: 10px;
  border: rgb(63, 65, 63) !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: green !important;
}

.-striped {
  background-color: white;
  font-family: sans-serif;
}

@media (min-width: 426px) {
  .ReactTable .-pagination {
    padding-left: 13% !important;
  }
  .ReactTable .-pagination .-btn {
    width: 50% !important;
  }
  .button {
    margin-right: -55px !important;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 0px 0px 0px 0px !important;
  }
  .ReactTable .pagination-bottom {
    width: 100% !important;
  }
}
.ReactTable .rt-thead .rt-th {
  background-color: #b3c2cc !important;
}
.ReactTable .rt-thead {
  height: 50%;
}

.ReactTable .rt-tbody .rt-tr {
  padding: 0 0 0 0px !important;
}
.ReactTable .rt-tbody .rt-tr {
  padding-right: 0px !important;
}
.ReactTable .rt-tbody .-even {
  background-color: #d8dde0 !important;
}
.tableheading {
  background-color: #b3c2cc;
  text-align: left;
  margin-bottom: 1% !important;
  margin-top: 0.5% !important;
  padding: 3px 5px 3px 5px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  "Helvetica Neue", sans-serif;
  font-size: 14px;
font-weight: 500;
width:100%;
  /* text-align: left;
  font-weight: bold;
  margin: 2px;
  padding-left: 4px;
  height: 35px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 500;
  padding-top: 0.5%; */
}

.refreshStyle{
  font-size: 26px !important;
  color: #08c !important;
  margin: 9px
  }

.atag {
  font-size: 12px;
  color: black;
  text-decoration: underline;
  width: 140px;
}

.dataContent {
  color: #1890ff;
  margin-left: 0.5%;
    font-size: 12px;
  text-decoration: none;
  
}
.tableminus {
 
  background-color: white;
  margin-top: 9px;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  /* padding-bottom: none; */
}
.tablebox1 {
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.tablebox2 {
  padding-top: 1%;
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  margin-top: 9px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.fcwidth {
  width: 71%;
  float: right;
}

.claimtype {
  font-family: sans-serif;
}

.popupheading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 12px !important;
}

.h3.popupheading {
  margin-top: 0% !important;
}

.company {
  font-size: 14px;
  font-weight: 600;
}

.claimsubtype {
  font-size: 14px;
  font-weight: 600;
}

.subtypes {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1%;
}

.tab-content {
  max-height: 85vh;
  overflow-y: auto;
}

.tabViewContent {
  background-color: #b3c2cc !important;
  padding: 9px;
}
.datepicker {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
}

.tableSubrow {
  font-size: 12px;
  display: flex;
}
.subrowRightContent {
  display: flex;
  margin-left: auto;
}

.supplierContent {
  background-color: #b3c2cc;
  padding: 9px;
  height: 84Vh;

  overflow-x: auto;
}

.supplierbox {
  /* background-color: rgba(214, 239, 251); */
  background-color: white;
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 9px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.date-width{
  width: "262px" !important
}
.supplierheading {
  text-align: left;
  /* font-weight: bold; */
  font-size: 12px;
  margin: 2px;
  height: 35px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  padding-top: 0.5%;
  border-bottom: 1px solid gray;
}

.a {
  margin-bottom: 10px;
  width: 70%;
display: -webkit-inline-box;
}

.tabb{
width: 70%;
display: -webkit-inline-box;
}
.newrequest-errortext{
  color: red;
  /* margin-left: 20px; */
}
.checkdetailsContent {
  background-color: #b3c2cc;
  padding: 9px;
  overflow-x: auto;
}

.checkdetailsbox {
  /* background-color: rgba(214, 239, 251); */
  background-color: white;
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 9px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.checkdetailsheading {
  text-align: left;
  font-size: 12px;
  margin: 2px;
  height: 35px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  padding-top: 0.5%;
  border-bottom: 1px solid gray;
}

.a {
  margin-bottom: 10px;
  width: 70%;
display: -webkit-inline-box;
}

.tabb{
width: 70%;
display: -webkit-inline-box;
}

.viewboxd {
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  margin-top: 10px;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.space {
  margin-bottom: 5px;
  margin-left: -15px !important;
}
.space .form-control {
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  min-height: 32px;
  overflow-x: auto;
  white-space: pre;
}
.space .form-control p {
  margin-bottom: 0px;
}
.Heading {
  font-weight: bold;
  padding: 2%;
  text-align: center;
}
.Table {
  padding: 2%;
}

.suppWEBContent {
  background-color: #b3c2cc;
  height: 86vh;
  padding: 9px;
  overflow-x: auto;
}

.outerbox {
  background-color: white;
  /* padding-bottom: 1%; */
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 9px;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.boxd {
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  margin-top: 10px;
}
#pdfobject {
  height: 76vh;
}
.mainBox {
  padding-top: 2%;
  padding-bottom: 2%;
}
.requestBtn {
  padding-top: 50px;
}
 .box{
   height: 76Vh;
 }
.responseContent {
  max-height: 84Vh;
  background-color: #b3c2cc;
  padding: 9px;
  overflow-x: auto;
  font-size: 12px;
  }
  
  .responsebox {
    /* background-color: rgba(214, 239, 251); */
    background-color: white;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 9px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }
  
  .responseheading {
    width: 100%;
    text-align: left;
    /* font-weight: bold; */
    font-size: 12px;
    margin: 2px;
    height: 35px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    padding-top: 0.5%;
    border-bottom: 1px solid gray;
  }

  .coloring {
      background-color: #b9d6e9;
      border-radius: 5px;
      padding-bottom: 1%;
      padding-top: 1%;
      font-weight: 700;
      /* color: rgb(201, 195, 195)' */
  }
/* .dynamic-tab-view,
.tableContent .nav-tabs {
  background: #b3c2cc;
  border-bottom: 1px solid #e6f1f8;
  display: flex;
}

.nav-tabs {
  padding-left: 9px;
}

.nav-tabs > li {
  float: left;
}

.dynamic-tab-view .nav-tabs > li.active > a,
.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li.active > a,
.tableContent .nav-tabs > li > a {
  padding: 5px 8px 5px 8px;
  margin-top: 11px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
}

.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li > a {
  color: #000000;
  text-decoration: none;
  background: linear-gradient(to bottom, #ffffff 0%, rgb(235, 236, 236) 100%);
}

.dynamic-tab-view .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: #008cd2;
  background: linear-gradient(to bottom, #1c77a5 0%, #008cd2 100%);
}

.tableContent .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(to bottom, rgb(52, 158, 33) 0%, rgb(86, 177, 70) 100%);
}

.side-tab {
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
  background: rgb(138, 149, 156);
}

.del-tab-deactive {
  display: none;
}
.del-tab-active {
  background: #008cd2;
  color: white;
}
.ref-tab {
  background: #56b146;
  color: white;
}
.tab-space {
  justify-content: space-between;
} */

.ant-btn-dangerous {
  color: #fff !important;
  background:#d9363e  !important;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  height: 30px;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
  color: #ffffff;
  text-decoration: none;
  background: #008cd2;
  background: linear-gradient(to bottom, #1c77a5 0%, #008cd2 100%);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
  font-weight: 500;
}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0 0;
}

.ant-tabs-tab-active .ant-tabs-tab-remove .anticon-close {
  margin: 0;
  color: #ffffff;
}
.ant-tabs-tab-remove {
  height: 18px;
  flex: none;
  margin-right: -4px;
  margin-left: 0px;
    color:#6d5e5e;
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  margin-top: 11px;
  color:#6d5e5e;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    font-size: 12px;
    border: none;
    outline: none;
    font-weight: 600;
    margin-right: 4px;
    /* line-height: 30px; */
  position: relative;
  /* display: inline-block; */
  box-sizing: border-box;
  /* padding-left: 0; */
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.dynamic-tab-view,
.tableContent .nav-tabs {
  background: #b3c2cc;
  border-bottom: 1px solid #e6f1f8;
  display: flex;
}

.nav-tabs {
  padding-left: 9px;
}

.nav-tabs > li {
  float: left;
  /* margin-bottom: 0px; */
}

.dynamic-tab-view .nav-tabs > li.active > a,
.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li.active > a,
.tableContent .nav-tabs > li > a {
  /* border-top-right-radius: 5px; */
  /* border-top-left-radius: 5px; */
  padding: 5px 8px 5px 8px;
  margin-top: 11px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
}

.dynamic-tab-view .nav-tabs > li > a,
.tableContent .nav-tabs > li > a {
  color: #000000;
  text-decoration: none;
  background: linear-gradient(to bottom, #ffffff 0%, rgb(235, 236, 236) 100%);
}

.dynamic-tab-view .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: #008cd2;
  background: linear-gradient(to bottom, #1c77a5 0%, #008cd2 100%);
}

.tableContent .nav-tabs > li.active > a {
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(to bottom, rgb(52, 158, 33) 0%, rgb(86, 177, 70) 100%);
}

.side-tab {
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
  /* border-bottom-right-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  font-size: 11px;
  border: none;
  outline: none;
  font-weight: 600;
  margin-right: 4px;
  background: rgb(138, 149, 156);
}

.del-tab-deactive {
  display: none;
}
.del-tab-active {
  background: #008cd2;
  color: white;
}
.ref-tab {
  background: #56b146;
  color: white;
}
.tab-space {
  justify-content: space-between;
}

.alertboxheader{
  display: inline;
  border-radius: 4px;
  box-shadow: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #ed6c02;
  display: flex;
  padding: 6px 16px;
  color: #000000;
  margin-left: 25%;
  margin-right:25%;
  margin-top:6px;
  z-index: 10;
  text-align: center;
}

.close-button{
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: .7;
  transition: .3s ease;
  align-self: flex-start;
}
.tablecontent {
    background-color: #b3c2cc !important;
    height: 89Vh;
  }
.tablecontent .nav-tabs > li > a {
    margin-top: 11px !important;
  }
.tabViewContent {
    background-color: #b3c2cc !important;
    height: calc(100vh - 89px);
    overflow-y: scroll;
    padding: 9px;
  }
.tableBox{
    background-color: white !important;
    /* padding-bottom: 1.5%; */
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
    /* padding: 1% !important; */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }
.topBox{
    padding: 1%;
}
.secondBox{
    padding:1%;
    /* padding-left: 2.5%; */
    text-decoration: underline;
}
.upperBodys {
  background-color: white !important;
  padding: 7px !important;
  margin-top: 21px !important;
  border-radius: 16px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.resultTab {
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  /* float:left; */
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
}
.resultTable {
  /* height: 33px; */
  background-color: white !important;
  /* padding: 5px !important; */
  margin-top: 21px !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5) !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.upperBodyTable2 {
  background-color: white !important;
  padding: 7px !important;
  /* margin-top: 21px !important; */
  border-radius: 16px !important;
  display: flex;
    flex-direction: column;
}
.right-to-left-buttons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.right-to-left-buttons > button {
  margin: 4px 8px;
}

/*  */
/* .ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 11px;
}
.ReactTable .rt-tbody .rt-td  {
  text-align: center;
  height: unset !important;
} */
.link-view {
  text-decoration: underline;
  cursor: pointer;
}
.header {
  width: 100%;
  background-color: aqua;
  height: 75px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABVYAAAAwCAIAAAECyYYzAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMjSURBVHhe7dfraVwxFIVRV5cSUpWbSSuuKAFDMJ/xMI/7kHQXrP+jOdra0n37/efXMd7ePxhR9ukwXQdnycacpcviMNmJEXSJ7CrTH02Xy+Yy8WF13Wwlg95Pf5hBZJ8O03VwlmzMKbomjpTNOF3Xx96yAUPpWtlDhj6srputZNDr6R8GvsqBuZqOA64mR4JPHROsKtHnto4PZpeI85yOFWaRKLOVDhrGlODOrn8PuCHnZzH9t8BXOTBr65+Hi8sJuZTOAq4mR+LKOhpYXs4AnzomWFJyz3cdGSwjWee2jg+mlnxzp84RZpRY84TOFGaRKPOKDhcGlwSzlQ4aBpTUMrjuHwA8JxcMU+vuAsBPcoWwsO49AFeWS4JraiwAWF5uAogmBoA1pO7hTk0SAHNJrcOLmjAAxpT6hp00eQCcKzUNR2ocAThMGhlO14wCsIeUL4ypwQXgRelZmEWjDMBD0qoMpbsFAFvJlcPgun8A8JxcMMyl2wkAd8qNwtS6uwDwk1whrKSbDQD/5c5gYd17AK4slwQX0RwAcDW5GLimxgKA5eUmgEYEgCWl/SGaGADWkLqHG5oeAOaVioc7NUkAzCW1Dk9oqgAYX6ocXtSEATCm1DdsqGkDYBypbNhDYwfA6dLUsLdGEIBTpJ3hSI0jAIdJI8MpmksA9pYihtM1owDsIeUL42hYAdhQOhcG1NQC8LpULQyuCQbgOalXmEWjDMBD0qrA03q6AAAAhpJvGGA/PX4AAABHyicKcJYeTgAAgG3lIwQYU48uAADAo/KZAcyoBxsAAOC7fEgA6+mxBwAArimfCsCltBEAAICF5XsA4FPLAgAAmF0e/QC3tUQAAIBZ5HEP8LT2CwAAMJS84AE2194BAABOkZc6wGHaRwAAwK7yIgc4XXsKAADYRF7eAMNqfwEAAA/JCxtgLi01AADgJ3lMA6yhZQcAAOTRDLCwNiAAAFxK3scAV9NaBACAVeUpDMA/7UoAAFhAXr0A/KQFCgAAc8kDF4CHtFUBAGBYecsC8KL2LAAADCIvVwD20PIFAIDj5ZEKwDFaxwAAsKv3j79yUH9XgVtL6AAAAABJRU5ErkJggg==);
  background-size: cover;
  background-repeat: no-repeat;
}

.glyphButton:hover {
  background-color: transparent;
}
/* .glyphButton:select {
  background-color: transparent;
} */
.headerRow {
  margin-bottom: 10px;
}
.tableContent {
  background-color: #b3c2cc;

  /* height: 84vh; */
  /* padding:9px; */
}
.tabViewContent {
  background-color: #b3c2cc !important;
  /* margin-top: 20px !important; */

  padding: 9px;
  /* padding-right: .5%;
  padding-left: .5% !important; */
}
/* .ReactTable {
  padding: 10px;
  border-radius: 10px;
  border: rgb(63, 65, 63) !important;
} */
/* .ReactTable .rt-tbody .rt-td:hover {
  border: 2px solid green;
} */
/* .ReactTable .rt-tbody .rt-tr-group:hover{
background-color: rgb(223, 250, 250);
}
.ReactTable .rt-tbody .rt-tr-group .-even:hover{
  background-color: rgb(5, 15, 15);
  } */
  .closeTable {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    /* margin-bottom: 0 !important; */
    font-size: 12px;
    font-weight: 600;
  }
/* .ReactTable .-pagination .-btn:hover {
  background-color: green !important;
} */
/* .form-control {
  width: 15% ;
  float: right;
} */
.-striped {
  background-color: white;
  font-family: sans-serif;
}
/* .ReactTable .-pagination .-previous  {
  width: 30% !important;
  height: 80% !important;
}
.ReactTable .-pagination .-next  {
  width: 30% !important;
  height: 80% !important;
} */
/* @media (min-width: 426px) {
  .ReactTable .-pagination {
    padding-left: 13% !important;
   
  } */
  /* .ReactTable .-pagination .-btn {
    width: 50% !important;
  }
  .button {
    margin-right: -55px !important;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 0px 0px 0px 0px !important;
  }
  .ReactTable .pagination-bottom {
    width: 100% !important;
  }
}
.ReactTable .rt-thead .rt-th {
  background-color: #b3c2cc !important;
}
.ReactTable .rt-thead {
  height: 50%;
}
.ReactTable .rt-tbody .rt-td {
  font-size: 11px;
  height: unset !important;
}
.ReactTable .rt-tbody .rt-tr {
  padding: 0 0 0 0px !important;
}
.ReactTable .rt-tbody .rt-tr {
  padding-right: 0px !important;
}
.ReactTable .rt-tbody .-even {
  background-color: #d8dde0 !important;
}
.ReactTable .rt-tbody .-odd {
  background-color: rgba(0,0,0,0.03) !important;
} */
.checkbox{
  width:50% !important; 
   margin:auto !important;
}

.tablebox1 {
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.tablebox2 {
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  margin-top: 9px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.fcwidth {
  width: 18%;
  float: right;
}

.claimtype {
  font-family: sans-serif;
}

.popupheading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 12px !important;
}

.h3.popupheading {
  margin-top: 0% !important;
}

.company {
  font-size: 14px;
  font-weight: 600;
}

.claimsubtype {
  font-size: 14px;
  font-weight: 600;
}

.subtypes {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1%;
}
.showfullString{
  text-align:left !important;
  white-space:nowrap !important;
  text-overflow:ellipsis !important;
  overflow:hidden !important;
  cursor: pointer;
}

.ant-tooltip{
  max-width: max-content;
  font-size: 12px;
}


.outerboxpass {
    background-color: white;
    
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 9px;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .passheading {
    text-align: left;
    font-size: 12px;
    margin: 2px;

    height: 35px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    padding: 1%;
    border-bottom: 1px solid gray;
  } 
  .password {
    background-color: #b3c2cc ; 
    
    padding:9px;
    overflow-x: auto;
  }
  .entryBox{
      width: 30%;
      display: inline;
  }
.margins{
    padding:3%;
}
.bottomspace{
    padding-bottom: 1%;
}
.highlight{
    border-color:red;
    width: 30%;
    display:inline;
}
.loginInput {
    width: 50%;
}
.anticon {
    font-size: 16px;
}


.loginBody {
    /* background: #457fca;   
    background: -webkit-linear-gradient(left, #5691c8, #457fca);
    background: -o-linear-gradient(left, #5691c8, #457fca);
    background: linear-gradient(to right, #5691c8, #457fca);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    background-image: url('../../assets/images/login_bg.jpg');
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: cover; */
    left: 0;
     height: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-top:-65px !important; */
}
.background-img{
    background: #457fca;
    background: linear-gradient(to right, #5691c8, #457fca);
    background-attachment: fixed;
    background-image: url(/static/media/login_bg.628afb2b.jpg);
    background-repeat: repeat;
    background-size: cover;
    height:100Vh;
    overflow: hidden;
}
.loginBody .container {
    text-align: center;
    width: 100%;
    height: 100vh; 
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}



.loginCard {
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 2px rgba(0, 0, 0, 0.24);
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) );
    margin-top: 9%;
    /* margin-left: 38%; */
    padding: 25px;
    max-width: 385px;
    width:100%;
    border-radius: 0.5em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* position: relative; */
    top: 26%;
   transform: translateY(-50%);
}
.inputsSection {
    padding: 5%;
    border-radius: 10px;
    
}
.errorMsg {
    color: #fffefe;
    padding: 5px;
    text-align: left;
    /* border: 1px solid #ce5353; */
    border-radius: 5px;
    /* margin-bottom: 15px; */
    font-size: 12px;
    font-weight: bolder;
    background: #ce5353;
}

.username,
.password {
    border-left: none;
    border-radius: 0 0.3rem 0.3rem 0;

   
}
.loginclass{
    display: none;
}
.greenMargin{
    border: 3px solid #eee;
    border-radius: 0.3em;

}
.loginMsg{
    text-align: left;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 1px 1px 2px rgba(0, 0, 0, 0.24);
    background-image: linear-gradient(rgba(245, 243, 243, 0.5), rgba(251, 247, 247, 0.79) );
    font-weight:500;
    padding: 14px;
    width: 96%;
    border-radius: 23px;
    /* border-radius: 0.5em; */
    display: block;
    margin-left: 2%;
    margin-right: 2%;
    /* position: absolute; */
    /* bottom: -50px; */
    margin-bottom: -4%;
    color:white;
    transform: translateY(-50%);
}
.loginButton {
    background: rgb(81,186,56) !important;
    color: white;
    width: 100%;
    border: none;
    margin-top:15px;
    border-radius: 0.3rem;
    height: 32px;
    
}

.loginButton:focus {
    background:rgb(173,196,218);
    color: rgb(66,106,147);
}
.spanT{

    color: rgb(0,124,195);
    padding-left: 10px!important;
    font-size: 200%;
    z-index: 1;
    position: relative;
    text-align: left;
    border-left: 4px solid rgb(81,186,56);
    /* margin-left: 247px; */
    /* margin-top: 56px; */
    margin-left: 19%!important;
    margin-top: 6.5%!important;
 
}

#userId {
    width:100% !important
}

#passWord {
    width:100% !important
}
.head_img{
    padding-left: 2%;
    padding-top: 3%;
    width: 18%;
    float : left;
    display: inline-block;
    height:100%;
    z-index: 1; position:relative;
    background-size:contain;
}

.content{
    margin-top: 61px;
    display: inline-block;
    /* float: left; */
    font-size: 22px;
    color: blue;
}


.alertbox{
    display: inline;
    border-radius: 4px;
    box-shadow: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #ed6c02;
    display: flex;
    padding: 6px 16px;
    color: #fff;
    text-align: left;
    margin-left: 20%;
    margin-right:20%;
    margin-top: 1%;
    z-index: 10;
    position: fixed;
    right: 0;
    left: 0;
}
.subjectContainer{
    text-align: left;
    color: white;
    font-weight: 700;
    padding-left: 15px;
}
.messageContainer{
    text-align: left;
    padding-left: 10px;
    color: #000;
    font-size: .7em;
}
.outerboxpass {
    background-color: white; 
    padding-left: 1%;
    padding-right: 1%;
  }
  .passheading {
    text-align: left;
    font-size: 12px;
    margin: 2px;

    height: 35px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    padding: 1%;
    border-bottom: 1px solid gray;
  } 
  .password {
    background-color: #b3c2cc ; 
    
    padding:9px;
    overflow-x: auto;
  }
  .entryBox1{
      width: 100%;
      display: inline;
  }
.margins{
    padding:3%;
}
.bottomspace{
    padding-bottom: 1%;
}
.highlight1{
    border-color:red;
    width: 100%;
    display:inline;
}
.loadingIndicator {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000000;
    background-color: #000;
    opacity: 0.5;
    display: flex;
    align-items: center;
}
.spinner {
    width: 70px;
    text-align: center;
    margin:0 auto;
}
.spinner>div {
    width: 18px;
    height: 18px;
    background-color: #008CD2;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
    animation-delay: -0.32s;
}
.spinner .bounce2 {
    animation-delay: -0.16s;
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

button:focus{
  outline: none !important;
}
