

.ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 11px;
}
.ReactTable .rt-tbody .rt-td {
  text-align: center;
  height: 23px !important;
  font-size: 11px;
}
.link-view {
  text-decoration: underline;
  cursor: pointer;
  color: #1890ff;
}
.ReactTable .-pagination .-btn {
  background-color: #3ba229 !important;
  color: #f7eeee !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: #3ba229 !important;
  color: #f7eeee !important;
}
.glyphButton:hover {
  background-color: transparent;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    font-weight: bold !important;
}
.headerRow {
  display: flex;
  /* margin-bottom: 15px; */
}
.claimDropdown{
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 18px
}
.tabViewContent {
  background-color: #b3c2cc !important;

  padding: 9px;
}
.oneLine{
  padding-left:10px;
}
.ReactTable {
  /* margin-top: 2%; */
  padding: 10px;
  border-radius: 10px;
  border: rgb(63, 65, 63) !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: green !important;
}
.first-column {
  text-align: left !important;
}

.-striped {
  background-color: white;
  font-family: sans-serif;
}

@media (min-width: 426px) {
  .ReactTable .-pagination {
    padding-left: 13% !important;
  }
  .ReactTable .-pagination .-btn {
    width: 50% !important;
  }
  .button {
    margin-right: -55px !important;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 0px 0px 0px 0px !important;
  }
  .ReactTable .pagination-bottom {
    width: 100% !important;
  }
}
.ReactTable .rt-thead .rt-th {
  background-color: #b3c2cc !important;
}
.ReactTable .rt-thead {
  height: 50%;
}

.ReactTable .rt-tbody .rt-tr {
  padding: 0 0 0 0px !important;
}

.ReactTable .rt-tbody .-even {
  background-color: #d8dde0 !important;
}
.link-view {
  text-decoration: underline;
  cursor: pointer;
}
.header {
  width: 100%;
  background-color: aqua;
  height: 75px;
  background-image: url("../../assets//images/header.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.glyphButton:hover {
  background-color: transparent;
}
/* .glyphButton:select {
  background-color: transparent;
} */
.headerRow {
  display: flex;
  margin-bottom: 15px;
  margin-right: 0px !important;
}
.tableContent {
  background-color: #b3c2cc;
}
.tabViewContent {
  background-color: #b3c2cc !important;

  padding: 9px;
}
.ReactTable {
  /* margin-top: 2%; */
  padding: 10px;
  border-radius: 10px;
  border: rgb(63, 65, 63) !important;
}

.ReactTable .-pagination .-btn:hover {
  background-color: green !important;
}

.-striped {
  background-color: white;
  font-family: sans-serif;
}

@media (min-width: 426px) {
  .ReactTable .-pagination {
    padding-left: 13% !important;
  }
  .ReactTable .-pagination .-btn {
    width: 50% !important;
  }
  .button {
    margin-right: -55px !important;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 0px 0px 0px 0px !important;
  }
  .ReactTable .pagination-bottom {
    width: 100% !important;
  }
}
.ReactTable .rt-thead .rt-th {
  background-color: #b3c2cc !important;
}
.ReactTable .rt-thead {
  height: 50%;
}

.ReactTable .rt-tbody .rt-tr {
  padding: 0 0 0 0px !important;
}
.ReactTable .rt-tbody .rt-tr {
  padding-right: 0px !important;
}
.ReactTable .rt-tbody .-even {
  background-color: #d8dde0 !important;
}
.tableheading {
  background-color: #b3c2cc;
  text-align: left;
  margin-bottom: 1% !important;
  margin-top: 0.5% !important;
  padding: 3px 5px 3px 5px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  "Helvetica Neue", sans-serif;
  font-size: 14px;
font-weight: 500;
width:100%;
  /* text-align: left;
  font-weight: bold;
  margin: 2px;
  padding-left: 4px;
  height: 35px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 500;
  padding-top: 0.5%; */
}

.refreshStyle{
  font-size: 26px !important;
  color: #08c !important;
  margin: 9px
  }

.atag {
  font-size: 12px;
  color: black;
  text-decoration: underline;
  width: 140px;
}

.dataContent {
  color: #1890ff;
  margin-left: 0.5%;
    font-size: 12px;
  text-decoration: none;
  
}
.tableminus {
 
  background-color: white;
  margin-top: 9px;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  /* padding-bottom: none; */
}
.tablebox1 {
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}
.tablebox2 {
  padding-top: 1%;
  background-color: white;
  padding-bottom: 1.5%;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
  margin-top: 9px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

.fcwidth {
  width: 71%;
  float: right;
}

.claimtype {
  font-family: sans-serif;
}

.popupheading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 12px !important;
}

.h3.popupheading {
  margin-top: 0% !important;
}

.company {
  font-size: 14px;
  font-weight: 600;
}

.claimsubtype {
  font-size: 14px;
  font-weight: 600;
}

.subtypes {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 1%;
}

.tab-content {
  max-height: 85vh;
  overflow-y: auto;
}

.tabViewContent {
  background-color: #b3c2cc !important;
  padding: 9px;
}
.datepicker {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
}

.tableSubrow {
  font-size: 12px;
  display: flex;
}
.subrowRightContent {
  display: flex;
  margin-left: auto;
}
